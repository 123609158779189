/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
import AOS from 'aos';
import 'aos/dist/aos.css';

import $ from 'jquery';
import 'jquery-scrollify';

export const onInitialClientRender = () => {
  var scrollup = new Event('scrollup');
  var scrolldown = new Event('scrolldown');
  // adding scroll event
  AOS.init({ once: false, duration: 1000, mirror: true });
  var previousIndex = 0;
  $.scrollify({
    section: '[data-scroll-class="scroll"]',
    setHeights: false,
    before: function(index) {
      window.dispatchEvent(previousIndex < index ? scrolldown : scrollup);
      previousIndex = $.scrollify.currentIndex();
    }
  });
  updateScrollify();
  window.onresize = updateScrollify;
  function updateScrollify() {
    AOS.refresh();
    if (window.innerWidth <= 992 && !$.scrollify.isDisabled()) {
      $.scrollify.disable();
    }
    else if (window.innerWidth > 992 && $.scrollify.isDisabled()) {
      $.scrollify.enable();
    }
  }
}
